import s from './Notifications.module.css'
import notificationSVG from '../../../../assets/notification.svg'

export function Notifications() {
  return (
    <>
      <div className={s.notification}>
        <div>
          <img src={notificationSVG} />
        </div>
      </div>
    </>
  )
}
