import { createTheme } from '@mui/material'
import muiButtons from './buttons/muiButtons'
import muiInputs from './inputs/muiInputs'
import muiSelects from './selects/muiSelects'
import muiSwitches from './switches/muiSwitches'
import muiDialogs from './dialogs/muiDialogs'
import muiDialogTitles from './dialog-titles/muiDialogTitles'
import muiTabs from './tabs/muiTabs'
import muiDialogActions from './dialog-actions/muiDialogActions'
import muiLinearProgresses from './linearProgresses/muiLinearProgresses'
import muiTypographies from './typographies/muiTypographies'
import muiDialogContents from './dialog-contents/muiDialogContents'
import muiTableRows from './table-rows/table-rows'
import muiTableCells from './table-cells/muiTableCells'
import muiCheckboxes from './checkboxes/muiCheckboxes'
import muiMenus from './menus/muiMenus'
import muiMenuItems from './menus/menuItems/muiMenuItems'
import muiContainers from './containers/muiContainers'
import muiAlerts from './alerts/muiAlerts'
import muiSliders from './sliders/muiSliders'
import muiTabItem from './tabs/tab/muiTabItem'
import muiPopover from './popover/muiPopover'
import muiFormGroups from './form-groups/muiFormGroups'
import muiAccordions from './accordions/muiAccordions'
import muiAccordionsDetails from './accordions-details/muiAccordionsDetails'
import muiTooltips from './tooltips/muiTooltips'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false
    sm: false
    md: false
    lg: false
    xl: false
    mobile: true
    desktop: true
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      desktop: 768,
    },
  },
  components: {
    MuiTypography: muiTypographies,
    MuiButton: muiButtons,
    MuiTextField: muiInputs,
    MuiSelect: muiSelects,
    MuiSwitch: muiSwitches,
    MuiDialog: muiDialogs,
    MuiDialogTitle: muiDialogTitles,
    MuiTabs: muiTabs,
    MuiDialogActions: muiDialogActions,
    MuiLinearProgress: muiLinearProgresses,
    MuiDialogContent: muiDialogContents,
    MuiTableRow: muiTableRows,
    MuiTableCell: muiTableCells,
    MuiCheckbox: muiCheckboxes,
    MuiMenu: muiMenus,
    MuiMenuItem: muiMenuItems,
    MuiContainer: muiContainers,
    MuiAlert: muiAlerts,
    MuiSlider: muiSliders,
    MuiTab: muiTabItem,
    MuiPopover: muiPopover,
    MuiFormGroup: muiFormGroups,
    MuiAccordion: muiAccordions,
    MuiAccordionDetails: muiAccordionsDetails,
    MuiTooltip: muiTooltips,
  },
})

export default theme
