import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiTableRows: {
  defaultProps?: ComponentsProps['MuiTableRow']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableRow']
  variants?: ComponentsVariants['MuiTableRow']
} = {
  styleOverrides: {
    root: {
      '&.row-cp': {
        cursor: 'pointer',
      },
      '&.row-cp:hover': {
        backgroundColor: 'var(--color-gray-light30)',
      },
      '&.th-row .MuiTableCell-root:nth-of-type(6) div': {
        justifyContent: 'flex-end',
      },
      '&.th-row .MuiTableCell-root': {
        padding: '11px 5px',
      },
      '&.modeling .MuiTableCell-root:nth-of-type(6) div': {
        justifyContent: 'flex-start',
      },
      '&.pricingHeadRow .MuiTableCell-root': {
        padding: '14px 16px 30px 0',
        borderBottom: 'none',
      },
      '&.pricingFooterRow .MuiTableCell-root': {
        padding: '30px 16px 0 0',
        borderTop: '1px solid var(--color-gray-light)',
        borderBottom: 'none',
      },
      '&.featureRow .MuiTableCell-root': {
        font: 'var(--font-title)',
        fontWeight: 400,
        borderTop: '1px solid var(--color-gray-light)',
        borderBottom: 'none',
        padding: '12px 16px 16px 0',
      },
      '&.isBold .MuiTableCell-root': {
        fontWeight: 500,
      },
      '&.billingHeadRow .MuiTableCell-root': {
        font: 'var(--font-xs)',
        color: 'var(--color-gray)',
        lineHeight: 'normal',
        fontWeight: 500,
        borderTop: 'none',
        textTransform: 'uppercase',
        padding: '0 0 20px 24px',
        borderBottom: '1px solid var(--color-gray-light)',
      },
      '&.billingHistoryRow .MuiTableCell-root': {
        padding: '20px 0 20px 24px',
      },
      '&.billingHistoryRow:last-of-type .MuiTableCell-root': {
        padding: '20px 0 20px 24px',
        borderBottom: 'none',
      },
      '&.billingHeadRow .MuiTableCell-root:nth-of-type(2)': {
        textAlign: 'center',
        padding: '0 0 20px',
      },
      '&.billingHeadRow .MuiTableCell-root:nth-of-type(3)': {
        textAlign: 'center',
        padding: '0 0 20px',
      },
      '&.billingHistoryRow .MuiTableCell-root:nth-of-type(2)': {
        padding: '20px 0',
      },
      '&.billingHistoryRow .MuiTableCell-root:nth-of-type(3)': {
        padding: '20px 0',
      },
    },
  },
}

export default muiTableRows
