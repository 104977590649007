import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiTooltips: {
  defaultProps?: ComponentsProps['MuiTooltip']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTooltip']
  variants?: ComponentsVariants['MuiTooltip']
} = {
  styleOverrides: {
    tooltip: {
      backgroundColor: '#FFF',
      padding: '20px 22px',
      color: 'rgba(0, 0, 0, 0.87)',
      borderRadius: '4px',
      boxShadow: '0px 2px 10px 0px rgba(34, 45, 51, 0.12)',
      maxWidth: 'none',
      whiteSpace: 'normal',
    },
  },
}

export default muiTooltips
