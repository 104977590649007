import s from './LaunchSteps.module.scss'
import { UserDto } from '../../../../auth/dto/user.dto'
import { StepItem } from '../step-item/StepItem'
import { IntegrationHostnameDto } from '../../../integration/data/dto/integration-hostname.dto'
import { useProducts } from '../../../products/hooks/use-products'
import { useState } from 'react'

interface LaunchStepsProps {
  user: UserDto
}

export const LaunchSteps = ({ user }: LaunchStepsProps) => {
  const { products } = useProducts()
  const userHasNoProducts = products.length === 0
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])

  const stepsData = [
    {
      checkmark: true,
      title: 'Create an account',
      text: 'Sign up on our platform with your email account in order to start your first project.',
    },
    {
      checkmark: !userHasNoProducts,
      title: 'Add your first product',
      text: 'Upload the first product information and visual assets in order to start creating a 3D/AR experience.',
      btn: 'Products',
      link: '/products',
    },
    {
      checkmark: user.userOrderedProduct,
      title: 'Order or submit a 3D model',
      text: 'Make the submission for the uploaded products.',
      btn: 'Products',
      link: '/products',
    },
    {
      checkmark: user.integrationHostnames?.some((item: IntegrationHostnameDto) => {
        return item.status === 'ACTIVE'
      }),
      title: 'Proceed with integration on your website',
      text: 'Receive the unique Activation key for the SDK and proceed with the integration into your website.',
      btn: 'Integration',
      link: '/integration',
    },
  ]

  return (
    <div className={s.wrapper}>
      {stepsData.map((item, index) => (
        <StepItem
          key={index}
          checkmark={item.checkmark}
          title={item.title}
          text={item.text}
          btn={item.btn}
          link={item.link}
          expandedIndexes={expandedIndexes}
          setExpandedIndexes={setExpandedIndexes}
          index={index}
        />
      ))}
    </div>
  )
}
