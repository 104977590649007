import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiTableCells: {
  defaultProps?: ComponentsProps['MuiTableCell']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableCell']
  variants?: ComponentsVariants['MuiTableCell']
} = {
  styleOverrides: {
    root: {
      '&.checkbox': {
        padding: '0',
        textAlign: 'center',
        width: '30px',
        borderBottom: '1px solid var(--color-gray-light)',
      },
      '&.table-title': {
        font: 'var(--font-xs)',
        fontWeight: '500',
        position: 'relative',
        top: '2px',
        padding: '11px 0',
        textAlign: 'left',
        textTransform: 'uppercase',
        color: 'var(--color-gray)',
        borderBottom: '1px solid var(--color-gray-light)',
      },
      '&.table-title .MuiButtonBase-root .MuiSvgIcon-root path': {
        fill: 'var(--color-gray)',
      },
      '&.row .MuiTableRow-root': {
        display: 'flex',
        gap: '30px',
        borderBottom: '1px solid var(--color-gray-light)',
      },
      '&.company': {
        width: '120px',
      },
      '&.organization': {
        fontSize: '12px',
        width: '160px',
      },
      '&.organization .site': {
        display: 'block',
        fontWeight: 400,
        fontSize: '12px',
        margin: '3px 0 0',
      },
      '&.organization .site a': {
        color: 'var(--color-gray)',
      },
      '&.menu': {
        textAlign: 'center',
      },
      '&.cell': {
        position: 'relative',
        zIndex: 100,
        // textTransform: 'capitalize',
        padding: '17px 5px',
        margin: '0 15px',
        fontFamily: 'var(--font-main)',
        borderBottom: '1px solid var(--color-gray-light)',
      },
      '&.sku': {
        font: 'var(--font-body)',
        color: 'var(--color-gray)',
        textTransform: 'uppercase',
      },
      '&.category': {
        font: 'var(--font-body)',
        color: 'var(--color-dark)',
        textTransform: 'capitalize',
      },
      '&.label': {
        position: 'relative',
        alignItems: 'center',
        font: 'var(--font-xs)',
        textTransform: 'uppercase',
        padding: '6px 12px',
        borderRadius: '12px',
        zIndex: 1,
        left: '5px',
        '::after': {
          position: 'absolute',
          content: '""',
          height: '22px',
          borderRadius: '12px',
          left: '0',
          zIndex: -1,
          top: '50%',
          transform: 'translateY(-50%)',
        },
      },
      '&.price': {
        font: 'var(--font-title)',
        color: 'var(--color-dark)',
        textAlign: 'right',
        paddingRight: '27px',
      },
      '&.pending': {
        color: 'var(--color-gray)',
        '::after': {
          width: '73px',
          background: 'var(--color-gray-light)',
        },
      },
      '&.done': {
        color: 'var(--color-blue)',
        '::after': {
          background: 'var(--color-gray-light)',
          width: '55px',
        },
      },
      '&.inWork': {
        color: '#F5B718',
        '::after': {
          background: '#FEF8E8',
          width: '71px',
        },
      },
      '&.failed': {
        color: '#F24822',
        '::after': {
          background: '#FEEDE9',
          width: '60px',
        },
      },
      '&.download': {
        textTransform: 'unset',
      },
      '&.freeTip': {
        '::after': {
          position: 'relative',
          top: '-2px',
          marginLeft: '7px',
          font: 'var(--font-xs)',
          content: '"Free"',
          textTransform: 'uppercase',
          color: 'var(--color-green)',
          padding: '6px 12px',
          background: 'var(--color-green-light)',
          borderRadius: '12px',
        },
      },
      '&.blueMark': {
        '::after': {
          position: 'relative',
          content: 'url("../src/assets/blue-mark.svg")',
        },
      },
      '&.name_row': {
        verticalAlign: 'middle',
        font: 'var(--font-title)',
        fontWeight: '400 !important',
        padding: '0 !important',
        border: 'none !important',
      },
      '&.footerCell': {
        font: 'var(--font-hero)',
        fontSize: '28px',
        lineHeight: 'normal',
        fontWeight: '500',
        color: 'var(--color-dark)',
      },
      '&.name_footer': {
        verticalAlign: 'middle',
        font: 'var(--font-title)',
        fontWeight: '400 !important',
        padding: '0 !important',
        border: 'none !important',
        color: 'var(--color-gray)',
      },
    },
  },
}

export default muiTableCells
