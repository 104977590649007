import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiInputs: {
  defaultProps?: ComponentsProps['MuiTextField']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField']
  variants?: ComponentsVariants['MuiTextField']
} = {
  styleOverrides: {
    root: {
      '&.search-input': {
        width: '240px',

        '& .MuiInputBase-root': {
          position: 'relative',
          paddingTop: '5px',
          font: 'var(--font-body)',
          color: 'var(--color-gray)',
          height: '40px',
          width: '100%',
          background: 'var(--color-white)',
          borderRadius: '20px',
          border: '1px solid #E9EEF1',
          padding: '12px 0 12px 15px',
          transition: '.2s ease-in-out',
        },
        '& .MuiInputBase-root:hover:before': {
          border: 'none',
        },
        '&.MuiInputBase-input': {
          padding: '5px 0 5px',

          '&::placeholder': {
            color: 'var(--color-gray)',
          },
        },
        '& .MuiInputBase-root:before': {
          border: 'none',
        },
        '& .MuiInputBase-root:after': {
          border: 'none',
        },
        '& .MuiInputBase-root:hover:not(.Mui-focused)': {
          border: '1px solid #909699',
        },
        '& .Mui-focused': {
          border: '1px solid var(--color-gray)',
          boxShadow: '0px 2px 10px rgba(34, 45, 51, 0.12)',
          transition: '.2s ease-in-out',
        },
        '& .Mui-focused .close-icon': {
          transform: 'scale(1)',
          transition: '.2s ease-in-out',
        },
        '& .close-icon': {
          transform: 'scale(0)',
          cursor: 'pointer',
          transition: '.2s ease-in-out',
        },
      },
      '&.standard-input': {
        position: 'relative',
        width: '100%',

        '& .MuiFormHelperText-root.Mui-error': {
          border: 'none !important',
          font: 'var(--font-body)',
        },
        '& .MuiFormHelperText-root': {
          position: 'absolute',
          margin: '40px 0 0 0',
          border: '0',
          ':focus-visible': {
            outline: 'none',
          },
        },
        '& .MuiInputBase-root': {
          font: 'var(--font-body)',
          height: '36px',
          width: '100%',
          border: '1px solid #E9EEF1',
          background: 'var(--color-white)',
          borderRadius: '3px',
          padding: '11px 13px',
          outline: 'none',
          transition: '.2s ease-in-out',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none !important',
        },
        '& .MuiInputBase-root:hover:before': {
          border: 'none',
        },
        '& .MuiInputBase-root:before': {
          border: 'none',
        },
        '& .MuiInputBase-root:after': {
          border: 'none',
        },
        '& .MuiInputBase-root:hover:not(.Mui-focused)': {
          border: '1px solid var(--color-gray) !important',
        },
        '& .Mui-focused': {
          border: '1px solid var(--color-gray) !important',
          transition: '.2s ease-in-out',
        },
      },
      '&.standard-input .Mui-disabled': {
        color: 'var(--color-gray)',
        backgroundColor: 'var(--color-gray-light30)',
      },
      '& .MuiInputBase-input': {
        padding: '5px 0 5px',
        background: 'transparent !important',

        '::placeholder': {
          position: 'relative',
          top: '2px',
          color: 'var(--color-gray-50)',
          opacity: '1',
        },
      },
      '&.https': {
        '& .MuiInputBase-root': {
          overflow: 'hidden',
          padding: '11px 5px 11px 0',
        },
      },
      '&.split-input-value': {
        width: '100%',

        '& .MuiInputBase-root': {
          overflow: 'visible',
          font: 'var(--font-body)',
          height: '36px',
          width: '100%',
          border: '1px solid #E9EEF1',
          background: 'var(--color-white)',
          borderRadius: '3px',
          padding: '11px 0 11px 13px',
          outline: 'none',
          transition: '.2s ease-in-out',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none !important',
        },
        '& .MuiInputBase-root:hover:before': {
          border: 'none',
        },
        '& .MuiInputBase-input': {
          padding: '8px 0 5px',
        },
        '& .MuiInputBase-root:before': {
          border: 'none',
        },
        '& .MuiInputBase-root:after': {
          border: 'none',
        },
        '& .MuiInputBase-root:hover:not(.Mui-focused)': {
          border: '1px solid var(--color-gray)',
        },
        '& .Mui-focused': {
          border: '1px solid var(--color-gray) !important',
          transition: '.2s ease-in-out',
        },
      },
      '&.split-input-value .Mui-disabled': {
        color: 'var(--color-gray)',
        backgroundColor: 'var(--color-gray-light30)',
      },
      '&.standardSelectInput .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&.standardSelectInput': {
        textTransform: 'capitalize',
        width: '100%',

        '& .Mui-focused': {
          border: '1px solid var(--color-gray) !important',
          transition: '.2s ease-in-out',
        },
        '& .MuiFormHelperText-root.Mui-error': {
          border: 'none !important',
          font: 'var(--font-body)',
        },
        '& .MuiFormHelperText-root': {
          position: 'absolute',
          margin: '40px 0 0 0',
          border: '0',
          ':focus-visible': {
            outline: 'none',
          },
        },
        '.MuiInputBase-root .MuiSelect-select': {
          padding: '15px 0 10px 0',
          fontFamily: 'var(--font-main)',
          fontSize: 'var(--body-size)',
          lineHeight: 'var(--line-height-body)',

          ':first-letter': {
            textTransform: 'uppercase',
          },
          ':before': {
            content: '"Select a category"',
            position: 'absolute',
            textTransform: 'none',
            color: 'var(--color-gray)',
          },
        },
        '& .MuiInputBase-root .MuiSvgIcon-root': {
          display: 'none',
        },
        '& .MuiInputBase-root .MuiSelect-select:after': {
          position: 'absolute',
          right: '15px',
          content: `url('data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2211%22%20height%3D%227%22%20viewBox%3D%220%200%2011%207%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10%201L5.5%206L1%201%22%20stroke%3D%22%23909699%22%20stroke-width%3D%221.2%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E')`,
        },
      },
      '&.standardSelectInput .MuiInputBase-root': {
        height: '36px',
        width: '100%',
        borderRadius: '3px',
        border: '1px solid #E9EEF1',
        fontFamily: 'var(--font-main)',
        fontText: '12px',
        color: 'var(--color-dark)',
        padding: '5px 0 5px 11px',
        '&fieldset': {
          border: 'none',
        },
        '&.MuiSelect-select': {
          padding: '7px 0 5px',
        },
        '::focus': {
          padding: '0 0 24px 8px',
          lineHeight: '36px',
          backgroundColor: 'none',
        },
        ':hover': {
          border: '1px solid var(--color-gray)',
          transition: '.2s ease-in-out',
        },
        '::before': {
          border: 'none !important',
        },
        '::after': {
          border: 'none',
        },
      },
      '&.standardSelectInput .Mui-disabled': {
        color: 'var(--color-gray)',
        backgroundColor: 'var(--color-gray-light30)',
      },
      '&.standardTextarea .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        border: '0',
      },
      '&.standardTextarea .MuiInputBase-root': {
        padding: '0',
      },
      '&.select-split-input': {
        transform: 'translateX(10px)',
        width: '100%',
        height: '34px !important',
        border: '0',
        fontFamily: 'var(--font-main)',
        fontText: '12px',
        padding: '8px 0 5px',
        color: 'var(--color-dark)',

        '&.select-split-input .MuiInputBase-root': {
          border: '0 !important',
          padding: '8px 0 5px',
        },
        '&.select-split-input .MuiOutlinedInput-notchedOutline': {
          border: '0',
        },
        ':focus': {
          padding: '0 0 24px 8px',
          lineHeight: '34px !important',
          background: 'var(--color-white)',
        },
        '.select-split-input .MuiInputBase-root:hover:not(.Mui-focused)': {
          border: '0 !important',
        },
        '::before': {
          border: 'none',
        },
        '::after': {
          border: 'none',
        },
      },
      '& .selectEnd .MuiInputBase-root:not(.Mui-focused)': {
        backgroundColor: 'transparent',
        border: '1px solid transparent',
      },
      '& .selectEnd': {
        transform: 'translate(9px, -2px)',
        width: '100%',
        height: '30px !important',
        border: '0',
        fontFamily: 'var(--font-main)',
        fontText: '12px',
        padding: '0 0 0 5px',
        color: 'var(--color-dark)',

        '& .MuiInputBase-root': {
          padding: '8px 0 8px 5px',
          border: '0',
          position: 'relative',
          top: '-1px',
          right: '0px',
        },
        '& .MuiInputBase-root .MuiSvgIcon-root': {
          display: 'none',
        },
        '& .MuiInputBase-root .MuiSelect-select:after': {
          position: 'absolute',
          right: '15px',
          content: `url('data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2211%22%20height%3D%227%22%20viewBox%3D%220%200%2011%207%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10%201L5.5%206L1%201%22%20stroke%3D%22%23909699%22%20stroke-width%3D%221.2%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E')`,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '0',
        },
        ':focus': {
          padding: '0',
          lineHeight: '34px !important',
          background: 'var(--color-white)',
        },
        '& .MuiInputBase-root:hover:not(.Mui-focused)': {
          border: '1px solid transparent',
        },
        '::before': {
          border: 'none',
        },
        '::after': {
          border: 'none',
        },
      },
    },
  },
}

export default muiInputs
