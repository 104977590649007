import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiSwitches: {
  defaultProps?: ComponentsProps['MuiSwitch']
  styleOverrides?: ComponentsOverrides<Theme>['MuiSwitch']
  variants?: ComponentsVariants['MuiSwitch']
} = {
  styleOverrides: {
    root: {
      '&.input-toggle': {
        position: 'relative',
        left: '-11px',
      },
      '&.input-toggle .MuiFormControlLabel-label': {
        marginLeft: '24px',
        marginTop: '2px',
      },
      '&.MuiSwitch-root': {
        transform: 'translateX(11px)',
        width: '42px',
        height: '22px',
        padding: '0',
        background: 'var(--color-gray-light)',
        borderRadius: '11px',
        gap: '10px',
      },
      '&.MuiSwitch-root .MuiButtonBase-root': {
        padding: '0',
      },
      '&.MuiSwitch-root .MuiButtonBase-root .MuiSwitch-thumb': {
        position: 'relative',
        width: '18px',
        height: '18px',
        top: '2px',
        left: '2px',
        boxShadow: '1px 0px 4px rgba(34, 45, 51, 0.08)',
      },
      '&.MuiSwitch-root .Mui-checked': {
        color: 'var(--color-white)',
        boxShadow: '1px 0px 4px rgba(34, 45, 51, 0.08)',
      },
      '&.MuiSwitch-root:has(.Mui-checked) .MuiSwitch-track': {
        backgroundColor: 'var(--color-blue)',
        opacity: '1',
        boxShadow: '1px 0px 4px rgba(34, 45, 51, 0.08)',
      },
      '&.MuiSwitch-root .MuiSwitch-track': {
        backgroundColor: 'var(--color-gray-light)',
        opacity: '1',
        boxShadow: '1px 0px 4px rgba(34, 45, 51, 0.08)',
      },
    },
  },
}

export default muiSwitches
